<template>
  <div>
    <div class="mid_title">
      <div class="Com_Dept_box">
        <div class="Com_Dept_box_top">
          <div class="Com_Dept_box_top_left">
            <span>公司设置</span>
          </div>
          <div class="Com_Dept_box_top_right">
            <span>部门设置</span>
          </div>
        </div>
        <div class="Com_Dept_box_mid">
          <div class="Com_Dept_box_mid_left">
            <div class="search-inp" style="display: flex; align-items: center">
              <i class="el-icon-search"></i>
              <el-autocomplete
                class="s-input"
                v-model="searchCompy"
                :fetch-suggestions="querySearch1"
                placeholder="请输入公司名称"
                :trigger-on-focus="false"
                @select="handleSelect1"
                clearable
                @clear="changCompyInp()"
                value-key="companyName"
              ></el-autocomplete>
              <i
                class="comp-icon-plus el-icon-plus"
                style="right: 10px"
                @click="onSubmit"
              ></i>
            </div>
            <el-tree
              v-if="JSON.stringify(searchCompyItem) == '{}'"
              :data="Companydata"
              :props="defaultProps"
              accordion
              node-key
              highlight-current
              @node-click="handleNodeClick"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span class="imgBox">
                  <img
                    style="width: 15px; margin-right: 10px"
                    v-show="node.id === nodeId"
                    src="../../assets/images/img1/del_img.png"
                    alt
                    @click="delItem(data)"
                  />
                  <img
                    style="width: 15px"
                    v-show="node.id === nodeId"
                    src="../../assets/images/img1/exit_img.png"
                    alt
                    @click="exitItem(data)"
                  />
                </span>
              </span>
            </el-tree>
            <div
              @click="handleClickItem(searchCompyItem, 0)"
              v-if="JSON.stringify(searchCompyItem) != '{}'"
              class="inp-box"
            >
              <span>{{ searchCompyItem.companyName }}</span>
              <span class="img">
                <img
                  style="width: 15px; margin-right: 10px"
                  src="../../assets/images/img1/del_img.png"
                  alt
                  @click="delItem(searchCompyItem)"
                />
                <img
                  style="width: 15px"
                  src="../../assets/images/img1/exit_img.png"
                  alt
                  @click="exitItem(searchCompyItem)"
                />
              </span>
            </div>
          </div>
          <div class="Com_Dept_box_mid_right">
            <div class="search-inp" style="display: flex; align-items: center">
              <i class="el-icon-search"></i>
              <el-autocomplete
                class="s-input"
                v-model="searchDept"
                :fetch-suggestions="querySearch2"
                placeholder="请输入部门名称"
                :trigger-on-focus="false"
                @select="handleSelect2"
                clearable
                @clear="changDeptInp"
                value-key="deptmentName"
              ></el-autocomplete>
              <i
                class="comp-icon-plus el-icon-plus"
                style="right: 10px"
                @click="onSubmit1"
              ></i>
            </div>
            <el-tree
              v-if="JSON.stringify(searchDeptItem) == '{}'"
              :data="deptdata"
              :props="defaultProps1"
              accordion
              @node-click="handleNodeClick1"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span class="imgBox">
                  <img
                    style="width: 15px"
                    v-show="node.id === nodeId"
                    src="../../assets/images/img1/del_img.png"
                    alt
                    @click="delItem2(data)"
                  />
                  <img
                    style="width: 15px"
                    v-show="node.id === nodeId"
                    src="../../assets/images/img1/exit_img.png"
                    alt
                    @click="exitItem2(data)"
                  />
                </span>
              </span>
            </el-tree>
            <div
              @click="handleClickItem(searchDeptItem, 1)"
              v-if="JSON.stringify(searchDeptItem) != '{}'"
              class="inp-box"
            >
              <span>{{ searchDeptItem.deptmentName }}</span>
              <span class="img">
                <img
                  style="width: 15px; margin-right: 10px"
                  src="../../assets/images/img1/del_img.png"
                  alt
                  @click="delItem2(searchDeptItem)"
                />
                <img
                  style="width: 15px"
                  src="../../assets/images/img1/exit_img.png"
                  alt
                  @click="exitItem2(searchDeptItem)"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="info" v-show="show === 1">
        <div class="info_com">部门信息</div>
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item></el-form-item>
          <el-form-item label="部门名称:">{{ form.deptmentName }}</el-form-item>
          <el-form-item label="部门编号:">{{ form.deptmentNo }}</el-form-item>
          <el-form-item label="上级部门:">{{ form.DeptmentName }}</el-form-item>
        </el-form>
      </div>
      <div class="info" v-show="show === 0">
        <div class="info_com">公司信息</div>
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item></el-form-item>
          <el-form-item label="公司名称:">{{ form.companyName }}</el-form-item>
          <el-form-item label="公司地址:">{{
            form.companyDetailAddr
          }}</el-form-item>
          <el-form-item label="公司编号:">{{ form.companyNo }}</el-form-item>
          <el-form-item label="联系电话:">{{ form.companyPhone }}</el-form-item>
          <el-form-item label="上级公司:">{{
            form.upCompanyName
          }}</el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog title="新增公司" :visible.sync="dialogVisible" width="20%">
      <el-form
        label-width="80px"
        :model="formLabelAlign"
        :rules="rules"
        ref="formLabelAlign"
        :show-message="false"
      >
        <el-form-item label="公司名称" prop="companyName">
          <el-input v-model="formLabelAlign.companyName"></el-input>
        </el-form-item>
        <el-form-item label="公司地址">
          <el-input v-model="formLabelAlign.companyDetailAddr"></el-input>
        </el-form-item>
        <el-form-item label="公司编号" prop="companyNo">
          <el-input v-model="formLabelAlign.companyNo"></el-input>
        </el-form-item>
        <el-form-item label="公司电话">
          <el-input v-model="formLabelAlign.companyPhone"></el-input>
        </el-form-item>
        <el-form-item label="上级公司">
          <el-select
            class="elselect"
            v-model="formLabelAlign.upCompanyId"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in Companydata1"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="负责人">
          <el-input v-model="formLabelAlign.type"></el-input>
        </el-form-item>
        <el-form-item label="邮政编码">
          <el-input v-model="formLabelAlign.types"></el-input>
        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addItem">保存并新增</el-button>
        <el-button type="primary" @click="addItem1">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改公司" :visible.sync="dialogVisibleexit" width="20%">
      <el-form
        label-width="80px"
        :model="formLabelAlign"
        :rules="rules"
        ref="formLabelAlign"
        :show-message="false"
      >
        <el-form-item label="公司名称" prop="companyName">
          <el-input v-model="formLabelAlign.companyName"></el-input>
        </el-form-item>
        <el-form-item label="公司地址">
          <el-input v-model="formLabelAlign.companyDetailAddr"></el-input>
        </el-form-item>
        <el-form-item label="公司编号" prop="companyNo">
          <el-input v-model="formLabelAlign.companyNo"></el-input>
        </el-form-item>
        <el-form-item label="公司电话">
          <el-input v-model="formLabelAlign.companyPhone"></el-input>
        </el-form-item>
        <el-form-item label="上级公司">
          <el-select
            class="elselect"
            v-model="formLabelAlign.upCompanyId"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in Companydata2"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="负责人">
          <el-input v-model="formLabelAlign.type"></el-input>
        </el-form-item>
        <el-form-item label="邮政编码">
          <el-input v-model="formLabelAlign.type"></el-input>
        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exitItem1">确 定</el-button>
        <el-button @click="dialogVisibleexit = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="新增部门" :visible.sync="dialogVisible1" width="20%">
      <el-form
        label-width="80px"
        :model="formLabelAlign1"
        :rules="rules"
        ref="formLabelAlign1"
        :show-message="false"
      >
        <el-form-item label="部门名称" prop="deptmentName">
          <el-input v-model="formLabelAlign1.deptmentName"></el-input>
        </el-form-item>
        <el-form-item label="部门编号">
          <el-input v-model="formLabelAlign1.deptmentNo"></el-input>
        </el-form-item>
        <el-form-item label="上级公司">
          <el-select
            class="elselect"
            v-model="formLabelAlign1.upCompanyId"
            placeholder="请选择"
            @change="FindDept"
            clearable
          >
            <el-option
              v-for="item in Companydata1"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级部门">
          <el-select
            class="elselect"
            v-model="formLabelAlign1.upDeptmentId"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in deptdata1"
              :key="item.value"
              :label="item.deptmentName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="负责人">
          <el-input v-model="formLabelAlign1.type"></el-input>
        </el-form-item>
        <el-form-item label="邮政编码">
          <el-input v-model="formLabelAlign1.type"></el-input>
        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addDeptItem">保存并新增</el-button>
        <el-button type="primary" @click="addDeptItem1">确 定</el-button>
        <el-button @click="dialogVisible1 = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改部门" :visible.sync="dialogVisible1exit" width="20%">
      <el-form
        label-width="80px"
        :model="formLabelAlign1"
        :rules="rules"
        ref="formLabelAlign1"
        :show-message="false"
      >
        <el-form-item label="部门名称" prop="deptmentName">
          <el-input v-model="formLabelAlign1.deptmentName"></el-input>
        </el-form-item>
        <el-form-item label="部门编号">
          <el-input v-model="formLabelAlign1.deptmentNo"></el-input>
        </el-form-item>
        <el-form-item label="上级部门">
          <el-select
            class="elselect"
            v-model="formLabelAlign1.upDeptmentId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in deptdata2"
              :key="item.value"
              :label="item.deptmentName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级公司">
          <el-select
            class="elselect"
            v-model="formLabelAlign1.upCompanyId"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in Companydata1"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="负责人">
          <el-input v-model="formLabelAlign1.type"></el-input>
        </el-form-item>
        <el-form-item label="邮政编码">
          <el-input v-model="formLabelAlign1.type"></el-input>
        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toexitItem">确 定</el-button>
        <el-button @click="dialogVisible1exit = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post, put } from "../../api/http";

export default {
  data() {
    return {
      Companydata: [],
      Companydata1: [],
      Companydata2: [],
      deptdata: [],
      deptdata1: [],
      deptdata2:[],
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleexit: false,
      dialogVisible1exit: false,
      defaultProps: {
        children: "companylist",
        label: "companyName",
      },
      defaultProps1: {
        children: "deptlist",
        label: "deptmentName",
      },
      show: 0,
      form: {},
      nodeId: "",
      formLabelAlign1: {
        deptmentNo: "",
        deptmentName: "",
        upCompanyId: "",
        upDeptmentId: "",
        isDelete: 0,
      },
      formLabelAlign: {
        id: 0,
        companyNo: "",
        companyName: "",
        companyDetailAddr: "",
        companyPhone: "",
        upCompanyId: "",
        isDelete: 0,
      },
      restaurants1: [],
      restaurants2: [],
      searchCompy: "",
      searchDept: "",
      searchCompyItem: {},
      searchDeptItem: {},
      rules: {
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        deptmentName: [
          { required: true, message: "请输入部门名称", trigger: "blur" },
        ],
      },
      companyId: null,
      deptId: null,
    };
  },
  // filters: {
  //   handleCpy(e) {
  //     this.Companydata1.forEach((i) => {
  //       if (i.id === e) {
  //         return i.companyName;
  //       }
  //     });
  //   },
  // },
  mounted() {
    this.getComdata();
    this.getSearchData();
  },
  methods: {
    getSearchData() {
      this.Companydata1 = this.$store.state.companyList;
      this.restaurants1 = this.$store.state.companyList;
      get("/api/Department/GetAllDept").then((res) => {
        this.restaurants2 = res.data;
      });
    },
    // 新增公司表单重置
    reset() {
      this.formLabelAlign = {
        companyNo: null,
        companyName: null,
        companyDetailAddr: null,
        companyPhone: null,
      };
      this.$nextTick(() =>{
        this.$refs["formLabelAlign"].resetFields();
      })
    },
    // 新增部门表单重置
    reset1() {
      this.formLabelAlign1 = {
        deptmentNo: '',
        deptmentName: '',
      };
      this.$nextTick(() =>{
        this.$refs["formLabelAlign1"].resetFields();
      })
    },
    onSubmit() {
      this.reset();
      this.formLabelAlign.upCompanyId = this.companyId;
      this.dialogVisible = true;
    },
    onSubmit1() {
      this.reset1();
      // this.formLabelAlign1.deptmentNo = "";
      // this.formLabelAlign1.deptmentName = "";
      this.formLabelAlign1.upCompanyId = this.companyId;
      this.formLabelAlign1.upDeptmentId = this.deptId;
      this.dialogVisible1 = true;
      if (this.companyId) {
        get("/api/Department/GetNoTree?CompId=" + this.companyId).then(
          (res) => {
            this.deptdata1 = res.data;
          }
        );
      }
    },
    getComdata() {
      get("/api/Company/GetTree").then((Res) => {
        this.Companydata = Res.data;
      });
    },
    getCompy() {
      get("/api/Company/GetAll").then((resp) => {
        if (resp.code == 200) {
          this.$store.dispatch("companyList", resp.data);
          sessionStorage.setItem("companyList", JSON.stringify(resp.data));
          this.Companydata1 = resp.data;
        }
      });
    },
    handleNodeClick(e, node) {
      this.companyId = e.id;
      this.deptId = "";
      this.show = 0;
      this.form = e;
      this.Companydata1.forEach((i) => {
        if (i.id === e.upCompanyId) {
          e.upCompanyName = i.companyName;
        }
      });
      this.nodeId = node.id;
      get("/api/Department/GetTree?CompId=" + e.id).then((res) => {
        this.deptdata = res.data;
      });
    },
    addItem() {
      this.$refs["formLabelAlign"].validate((valid) => {
        if (valid) {
          if (
            this.formLabelAlign.upCompanyId === "" ||
            this.formLabelAlign.upCompanyId === null
          ) {
            // this.formLabelAlign.upCompanyId = 0;
            // 不能添加最外层的公司
            this.$message({
              type: "warning",
              message: "请选择上级公司",
            });
            return;
          }
          post("/api/Company", this.formLabelAlign).then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: res.message,
            });
            // this.onSubmit();
            (this.formLabelAlign = {
              companyNo: "",
              companyName: "",
              companyDetailAddr: "",
              companyPhone: "",
              upCompanyId: "",
            }),
            this.getComdata();
            this.getCompy();
          });
        }
      });
    },
    delItem(e) {
      this.$confirm("此操作将永久删除该公司, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(e);
          deleteMethod("/api/Company/" + e.id).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.getComdata();
            this.getCompy();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    exitItem(e) {
      console.log(e);
      this.formLabelAlign = e;
      this.formLabelAlign.upCompanyId = this.formLabelAlign.upCompanyId == 0 ? null : this.formLabelAlign.upCompanyId;
      this.dialogVisibleexit = true;
      // this.Companydata1.map((item,index) =>{
      //   if(item.id == e.id){
      //     this.Companydata1.splice(index,1)
      //   }
      // })
      this.Companydata2 = this.Companydata1.filter(item => item.id !== e.id);
    },
    exitItem1() {
      this.$refs["formLabelAlign"].validate((valid) => {
        if (valid) {
        if (
            this.formLabelAlign.upCompanyId === "" ||
            this.formLabelAlign.upCompanyId === null
          ) {
            // this.formLabelAlign.upCompanyId = 0;
            // 不能添加最外层的公司
            this.$message({
              type: "warning",
              message: "请选择上级公司",
            });
            return;
          }
          put("/api/Company/", this.formLabelAlign).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.dialogVisibleexit = false;
            this.getComdata();
            this.getCompy();
          });
        }
      });
    },
    addDeptItem() {
      this.$refs["formLabelAlign1"].validate((valid) => {
        if (valid) {
          if (
            this.formLabelAlign1.upDeptmentId === "" ||
            this.formLabelAlign1.upDeptmentId === null
          ) {
            this.formLabelAlign1.upDeptmentId = 0;
          }
          post("/api/Department", this.formLabelAlign1)
            .then((res) => {
              console.log(res);
              this.$message({
                type: "success",
                message: res.message,
              });
            })
            .then(() => {
              get("/api/Department/GetTree?CompId=" + this.companyId).then(
                (res) => {
                  this.deptdata = res.data;
                }
              );
            });
          // this.onSubmit1();
          this.formLabelAlign1 = {
            deptmentNo: "",
            deptmentName: "",
            upCompanyId: "",
            upDeptmentId: "",
          };
        }
      });
    },
    addDeptItem1() {
      this.$refs["formLabelAlign1"].validate((valid) => {
        if (valid) {
          if (
            this.formLabelAlign1.upDeptmentId === "" ||
            this.formLabelAlign1.upDeptmentId === null
          ) {
            this.formLabelAlign1.upDeptmentId = 0;
          }
          post("/api/Department", this.formLabelAlign1)
            .then((res) => {
              console.log(res);
              this.$message({
                type: "success",
                message: res.message,
              });
            })
            .then(() => {
              get("/api/Department/GetTree?CompId=" + this.companyId).then(
                (res) => {
                  this.deptdata = res.data;
                }
              );
            });
          this.dialogVisible1 = false;
        }
      });
    },
    exitItem2(e) {
      this.formLabelAlign1 = e;
      this.formLabelAlign1.upDeptmentId = this.formLabelAlign1.upDeptmentId == 0 ? null : this.formLabelAlign1.upDeptmentId;
      get("/api/Department/GetNoTree?CompId=" + e.upCompanyId).then((res) => {
        this.deptdata1 = res.data;
        // this.deptdata1.forEach((item,index) =>{
        //   if(item.id == e.id){
        //     this.deptdata1.splice(index,1)
        //   }
        // })
        this.deptdata2 = this.deptdata1.filter(item => item.id !== e.id);
      });
      this.dialogVisible1exit = true;
    },
    delItem2(e) {
      this.$confirm("此操作将永久删除该部门, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(e);
          deleteMethod("/api/Department/" + e.id).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            get("/api/Department/GetTree?CompId=" + e.upCompanyId).then(
              (res) => {
                this.deptdata = res.data;
              }
            );
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    FindDept(e) {
      this.companyId = e;
      get("/api/Department/GetNoTree?CompId=" + e).then((res) => {
        this.deptdata1 = res.data;
      });
    },
    toexitItem() {
      this.$refs["formLabelAlign1"].validate((valid) => {
        if (valid) {
          put("/api/Department", this.formLabelAlign1)
            .then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
            })
            .then(() => {
              get(
                "/api/Department/GetTree?CompId=" +
                  this.formLabelAlign1.upCompanyId
              ).then((res) => {
                this.deptdata = res.data;
              });
              this.dialogVisible1exit = false;
            });
        }
      });
    },
    addItem1() {
      this.$refs["formLabelAlign"].validate((valid) => {
        if (valid) {
          if (
            this.formLabelAlign.upCompanyId === "" ||
            this.formLabelAlign.upCompanyId === null
          ) {
            // this.formLabelAlign.upCompanyId = 0;
            // 不能添加最外层的公司
            this.$message({
              type: "warning",
              message: "请选择上级公司",
            });
            return;
          }
          post("/api/Company", this.formLabelAlign).then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: res.message,
            });
            this.dialogVisible = false;
            this.getComdata();
            this.getCompy();
          });
        }
      });
    },
    handleNodeClick1(e, node) {
      console.log(e);
      this.deptId = e.id;
      this.show = 1;
      this.nodeId = node.id;
      this.form = e;
      this.deptdata.forEach((i) => {
        if (i.id === e.upDeptmentId) {
          e.DeptmentName = i.deptmentName;
        }
      });
    },
    // 公司部门查询
    querySearch1(queryString, cb) {
      var restaurants = this.restaurants1;
      this.changQuerySearch(queryString, restaurants, cb, 1);
    },
    querySearch2(queryString, cb) {
      var restaurants = this.restaurants2;
      this.changQuerySearch(queryString, restaurants, cb, 2);
    },
    changQuerySearch(queryString, restaurants, cb, index) {
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString, index))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString, index) {
      //index区分当前操作在查公司还是部门
      return (restaurant) => {
        var name =
          index == 1 ? restaurant.companyName : restaurant.deptmentName;
        return name.toLowerCase().match(queryString.toLowerCase());
      };
    },
    changCompyInp() {
      this.searchCompyItem = {};
    },
    handleSelect1(e) {
      this.searchCompyItem = e;
    },
    changDeptInp() {
      this.searchDeptItem = {};
    },
    handleSelect2(e) {
      this.searchDeptItem = e;
    },
    handleClickItem(e, index) {
      this.show = index == 1 ? 1 : 0;
      this.form = e;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/style/Com_Dept.css";
.custom-tree-node{
  width: calc(100% - 36px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  &>span:nth-child(1){
    white-space: nowrap;  
    overflow: hidden;  
    text-overflow: ellipsis;  
    flex: 1;
  }
  .imgBox{
    width: 40px;
  }
}
</style>
